import { http, HttpResponse } from 'msw'

import { allProfiles } from 'app/mocks/data/users/profiles'

import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'

export const getAllProfiles = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.profiles.LIST}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        const managerIdsFilter = url.searchParams.get('client__id__in')
        const nameFilter = url.searchParams.get('name__icontains')
        const descriptionFilter = url.searchParams.get('description__icontains')

        let allProfilesFiltered = allProfiles

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managerIdsFilter)

            allProfilesFiltered = allProfilesFiltered.filter((profile) =>
                managerIdsSplit.includes(profile.client.id),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allProfilesFiltered = allProfilesFiltered.filter((profile) =>
                profile.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Description filter
        if (!!descriptionFilter) {
            allProfilesFiltered = allProfilesFiltered.filter((profile) =>
                profile.description
                    .toLowerCase()
                    .includes(descriptionFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allProfilesFiltered.length > limit
                ? offset + limit
                : allProfilesFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allProfilesFiltered.slice(offset, limitArray)
                : allProfilesFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allProfilesFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allProfilesFiltered.length,
            next,
            results,
        })
    },
)
