import { TypeMachine } from 'app/types/shared/machine'

export const terminalStandard1 = {
    id: 101,
    name: 'Borne 1 mock',
    category: TypeMachine.enum.TERMINAL,
    description: '',
    stock_usage_percentage: 0,
    number_of_products: 0,
    max_products: 7,
    provider: '',
    software_version: '10',
    build_date: '2023-10-31',
    installation_date: '2023-10-31',
    distributor_volume: 10,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '',
    mode: '',
    enabled: false,
    online: false,
    is_low_stock: false,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 10,
    time_high_stock_check: '11:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    default_locale_customer: 'en-GB',
    default_locale_intervenor: 'fr-FR',
    has_coin_acceptor: true,
    has_banknote_reader: false,
    has_printer: true,
    has_id_card_reader: false,
    has_ask_age_confirmation: false,
    user: 165,
    components: [],
}

export const terminalStandard2 = {
    id: 102,
    name: 'Borne 2 mock',
    category: TypeMachine.enum.TERMINAL,
    description: '',
    stock_usage_percentage: 0,
    number_of_products: 0,
    max_products: 7,
    provider: '',
    software_version: '10',
    build_date: '2023-10-31',
    installation_date: '2023-10-31',
    distributor_volume: 10,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '',
    mode: '',
    enabled: false,
    online: false,
    is_low_stock: false,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 10,
    time_high_stock_check: '11:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    default_locale_customer: 'en-GB',
    default_locale_intervenor: 'fr-FR',
    has_coin_acceptor: true,
    has_banknote_reader: false,
    has_printer: true,
    has_id_card_reader: false,
    has_ask_age_confirmation: false,
    user: 166,
    components: [],
}

export const terminalStandard3 = {
    id: 103,
    name: 'Borne 3 mock',
    category: TypeMachine.enum.TERMINAL,
    description: '',
    stock_usage_percentage: 0,
    number_of_products: 0,
    max_products: 7,
    provider: '',
    software_version: '10',
    build_date: '2023-10-31',
    installation_date: '2023-10-31',
    distributor_volume: 10,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '',
    mode: '',
    enabled: false,
    online: false,
    is_low_stock: false,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 10,
    time_high_stock_check: '11:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    default_locale_customer: 'en-GB',
    default_locale_intervenor: 'fr-FR',
    has_coin_acceptor: true,
    has_banknote_reader: false,
    has_printer: true,
    has_id_card_reader: false,
    has_ask_age_confirmation: false,
    user: 167,
    components: [],
}
