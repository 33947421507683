import * as clients from 'app/mocks/data/park/clients/list'

export const service1 = {
    id: 1,
    name: 'Service 1',
    client: clients.client1,
    domain_name: 'eat.com',
}

export const service2 = {
    id: 2,
    name: 'Service 2',
    client: clients.client1,
}

export const service3 = {
    id: 3,
    name: 'Service 3',
    client: clients.client1,
}

export const service4 = {
    id: 4,
    name: 'Service 4',
    client: clients.client1,
    domain_name: 'product.com',
}

export const service5 = {
    id: 5,
    name: 'Service 5',
    client: clients.client1,
}

export const service6 = {
    id: 6,
    name: 'Service 6',
    client: clients.client2,
}
