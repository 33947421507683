import { TypeMachine } from 'app/types/shared/machine'

export const distributorStandard1 = {
    id: 201,
    name: 'Distributor 1 Mock',
    category: TypeMachine.enum.DISTRIBUTOR,
    description: 'DistributeurDev',
    stock_usage_percentage: 5,
    number_of_products: 12,
    max_products: 250,
    provider: 'E-thik',
    software_version: '1',
    build_date: '2022-08-24',
    installation_date: '2022-08-24',
    distributor_volume: 200,
    connexion_type: 'et',
    paiement_method: 'cb',
    private_id: '003595959',
    mode: 'Vente',
    enabled: true,
    online: true,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 90,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 91,
    components: [],
}

export const distributorStandard2 = {
    id: 202,
    name: 'Distributor 2 Mock',
    category: TypeMachine.enum.DISTRIBUTOR,
    description: 'DistributeurDev',
    stock_usage_percentage: 5,
    number_of_products: 12,
    max_products: 250,
    provider: 'E-thik',
    software_version: '1',
    build_date: '2022-08-24',
    installation_date: '2022-08-24',
    distributor_volume: 200,
    connexion_type: 'et',
    paiement_method: 'cb',
    private_id: '003595959',
    mode: 'Vente',
    enabled: true,
    online: true,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 90,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 92,
    components: [],
}
