import { http, HttpResponse } from 'msw'
import { allPermissions } from 'app/mocks/data/users/permissions'
import { api } from 'app/utils/constants'

export const getAllPermissions = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.PERMISSIONS}`,
    () => {
        return HttpResponse.json({
            count: allPermissions.length,
            results: allPermissions,
        })
    },
)
