import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allProfiles } from 'app/mocks/data/users/profiles'
import { api } from 'app/utils/constants'
import { permissionsData } from 'app/mocks/data/users/permissions/list'

export const updateProfile = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.profiles.DETAILS}`,
    async ({ request, params }) => {
        const data = await request.json()
        const { id } = params

        const profileFound = allProfiles.find(
            (profile) => profile.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!profileFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const permissionsUpdated = permissionsData.filter((permission) =>
            data.permissions.includes(permission.id),
        )

        const profileUpdated = {
            ...profileFound,
            ...data,
            permissions: permissionsUpdated,
        }

        return HttpResponse.json(profileUpdated)
    },
)
