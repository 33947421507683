const machines = {
    all: {
        READ: 'view_machine',
        UPDATE: 'change_machine',
        CREATE: 'add_machine',
        DELETE: 'delete_machine',
    },

    machineOptions: {
        CREATE: 'add_machineoption',
        READ: 'view_machineoption',
        UPDATE: 'change_machineoption',
        DELETE: 'delete_machineoption',
    },

    machineRelations: {
        CREATE: 'add_machinegrid',
        READ: 'view_machinegrid',
        UPDATE: 'change_machinegrid',
        DELETE: 'delete_machinegrid',
    },

    marketingImages: {
        CREATE: 'add_marketingimage',
        READ: 'view_marketingimage',
        UPDATE: 'change_marketingimage',
        DELETE: 'delete_marketingimage',
    },

    marketingVideos: {
        CREATE: 'add_marketingvideo',
        READ: 'view_marketingvideo',
        UPDATE: 'change_marketingvideo',
        DELETE: 'delete_marketingvideo',
    },

    planograms: {
        CREATE: 'add_machinegriditem',
        READ: 'view_machinegriditem',
        UPDATE: 'change_machinegriditem',
        DELETE: 'delete_machinegriditem',
    },

    stock: {
        READ: 'view_stock',
    },

    technicalData: {
        CREATE: 'add_technicaldata',
        READ: 'view_technicaldata',
        UPDATE: 'change_technicaldata',
        DELETE: 'delete_technicaldata',
    },
}

const operations = {
    deliveries: {
        READ: 'view_delivery',
    },

    destocking: {
        DELETE: 'delete_encodedproduct',
        READ: 'view_encodedproduct',
    },

    encodingReports: {
        READ: 'view_encodingreport',
    },

    incidents: {
        READ: 'view_event',
    },

    maintenanceAndSupply: {
        READ: 'view_intervention',
    },
}

const park = {
    clients: {
        CREATE: 'add_sitecustomer',
        READ: 'view_sitecustomer',
        UPDATE: 'change_sitecustomer',
        DELETE: 'delete_sitecustomer',
    },

    sites: {
        CREATE: 'add_site',
        READ: 'view_site',
        UPDATE: 'change_site',
        DELETE: 'delete_site',
    },
}

const products = {
    discounts: {
        CREATE: 'add_discount',
        READ: 'view_discount',
        UPDATE: 'change_discount',
        DELETE: 'delete_discount',
    },

    discountsPriority: {
        CREATE: 'add_discountpriority',
        READ: 'view_discountpriority',
        UPDATE: 'change_discountpriority',
        DELETE: 'delete_discountpriority',
    },

    priceLine: {
        CREATE: 'add_priceline',
        READ: 'view_priceline',
        UPDATE: 'change_priceline',
        DELETE: 'delete_priceline',
    },

    priceTable: {
        CREATE: 'add_pricetable',
        READ: 'view_pricetable',
        UPDATE: 'change_pricetable',
        DELETE: 'delete_pricetable',
    },

    productList: {
        CREATE: 'add_product',
        READ: 'view_product',
        UPDATE: 'change_product',
        DELETE: 'delete_product',
    },

    suppliers: {
        CREATE: 'add_supplierproduct',
        READ: 'view_supplierproduct',
        UPDATE: 'change_supplierproduct',
        DELETE: 'delete_supplierproduct',
    },
}

const settings = {
    allergens: {
        CREATE: 'add_allergens',
        READ: 'view_allergens',
        UPDATE: 'change_allergens',
        DELETE: 'delete_allergens',
    },

    emails: {
        CREATE: 'add_emailparameter',
        READ: 'view_emailparameter',
        UPDATE: 'change_emailparameter',
    },

    managers: {
        CREATE: 'add_categoryclient',
        READ: 'view_categoryclient',
        UPDATE: 'change_categoryclient',
        DELETE: 'delete_categoryclient',
    },

    personalisation: {
        CREATE: 'add_colorsparameters',
        READ: 'view_colorsparameters',
        UPDATE: 'change_colorsparameters',
    },

    productCategories: {
        CREATE: 'add_categoryproduct',
        READ: 'view_categoryproduct',
        UPDATE: 'change_categoryproduct',
        DELETE: 'delete_categoryproduct',
    },

    siteTypes: {
        CREATE: 'add_sitetype',
        READ: 'view_sitetype',
        UPDATE: 'change_sitetype',
        DELETE: 'delete_sitetype',
    },

    subcategoryProducts: {
        CREATE: 'add_subcategoryproduct',
        READ: 'view_subcategoryproduct',
        UPDATE: 'change_subcategoryproduct',
        DELETE: 'delete_subcategoryproduct',
    },

    tickets: {
        CREATE: 'add_ticketparameter',
        READ: 'view_ticketparameter',
        UPDATE: 'change_ticketparameter',
    },

    zones: {
        CREATE: 'add_zone',
        READ: 'view_zone',
        UPDATE: 'change_zone',
        DELETE: 'delete_zone',
    },
}

const transactions = {
    disputes: {
        READ: 'view_dispute',
    },

    disputedProducts: {
        READ: 'view_disputeproduct',
    },

    refills: {
        READ: 'view_creditrefill',
    },

    refunds: {
        READ: 'view_refund',
    },

    sales: {
        READ: 'view_sale',
    },
}

const users = {
    deliveries: {
        CREATE: 'add_deliveryman',
        READ: 'view_deliveryman',
        UPDATE: 'change_deliveryman',
        DELETE: 'delete_deliveryman',
    },

    managers: {
        CREATE: 'add_client',
        READ: 'view_client',
        UPDATE: 'change_client',
        DELETE: 'delete_client',
    },

    konvives: {
        CREATE: 'add_machineuser',
        READ: 'view_machineuser',
        UPDATE: 'change_machineuser',
        DELETE: 'delete_machineuser',
    },

    konsole: {
        CREATE: 'add_user',
        READ: 'view_user',
        UPDATE: 'change_user',
        DELETE: 'delete_user',
    },

    labelGuns: {
        CREATE: 'add_labelgun',
        READ: 'view_labelgun',
        UPDATE: 'change_labelgun',
        DELETE: 'delete_labelgun',
    },

    profiles: {
        CREATE: 'add_group',
        READ: 'view_group',
        UPDATE: 'change_group',
        DELETE: 'delete_group',
    },

    technicians: {
        CREATE: 'add_technician',
        READ: 'view_technician',
        UPDATE: 'change_technician',
        DELETE: 'delete_technician',
    },
}

export const permissions = {
    machines,
    operations,
    park,
    products,
    settings,
    transactions,
    users,
}
