import { createProfile } from './createProfile'
import { deleteProfile } from './deleteProfile'
import { getAllProfiles } from './getAllProfiles'
import { getProfile } from './getProfile'
import { updateProfile } from './updateProfile'

export const profiles = [
    createProfile,
    deleteProfile,
    getAllProfiles,
    getProfile,
    updateProfile,
]
