import {
    Button,
    OverlayArrow,
    Tooltip,
    TooltipTrigger,
} from 'react-aria-components'
import { makeStyles } from '@material-ui/core'

import { joinStyles, palette, various } from 'app/utils/theme'

const useStyles = makeStyles(() => ({
    trigger: {
        all: 'unset',
    },

    tooltip: {
        maxWidth: '500px',
        padding: various.padding,
        textAlign: 'justify',
        backgroundColor: palette.background.primary,
        borderRadius: various.borderRadius,
        boxShadow: various.boxShadow.component,

        '& > div > svg': {
            fill: palette.background.primary,
        },

        "& [data-placement='top'] > svg": {
            marginBottom: '10px',
            transform: 'rotate(0deg)',
        },

        "& [data-placement='bottom'] > svg": {
            marginTop: '10px',
            transform: 'rotate(180deg)',
        },

        "& [data-placement='left'] > svg": {
            transform: 'rotate(-90deg)',
        },

        "& [data-placement='right'] > svg": {
            transform: 'rotate(90deg)',
        },
    },
}))

export const ToolTip = ({
    children,
    className,
    offset = 15,
    content,
    ...properties
}) => {
    const classes = useStyles()

    return (
        <TooltipTrigger
            delay={0}
            closeDelay={0}
        >
            {/* Trigger children */}
            <Button className={classes.trigger}>{children}</Button>

            {/* Tooltip */}
            <Tooltip
                className={joinStyles(classes.tooltip, className)}
                offset={offset}
                {...properties}
            >
                <OverlayArrow>
                    <svg
                        width={15}
                        height={15}
                        viewBox='0 0 15 15'
                    >
                        <path d='M0 0 L8 8 L15 0' />
                    </svg>
                </OverlayArrow>

                {content}
            </Tooltip>
        </TooltipTrigger>
    )
}
